import React, { useState } from "react";
import { Helmet } from "react-helmet";
import {
  BurgerBoi,
  ExplodingPigeon,
  Faq,
  Footer,
  Hero,
  Intro,
  ImageGrid,
  Layout,
  BurgerMenu,
  Navigation,
  RarityBrowser,
  Roadmap,
  Spidey,
  MintContainer,
  MintStep1,
  MintStep2,
} from "../components";

import "./index.scss";

const MintPage = () => {
  const [menuOpen, setMenuOpen] = useState(false);


  const toggleMenu = () => {
    if (!menuOpen) {
      window.scrollTo(0, 0);
    }
    setMenuOpen(!menuOpen);
  };

  const goToAnchor = () => {
    toggleMenu();
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cult of Pigeons</title>
        <link rel="canonical" href="https://cultofpigeons.io" />
        <meta property="og:site_name" content="Cult of Pigeons" />
        <meta property="og:title" content="Cult of Pigeons" />
        <meta
          property="og:description"
          content="Welcome to the Cult of Pigeons! The first ultra high quality art collection of 10.000 unique pigeons, ready to take over Cardano and after, the world!"
        />
        <meta
          property="og:image:secure_url"
          itemprop="image"
          content="https://d90xur3si4z2s.cloudfront.net/ogimage-256x256.png"
        />
        <meta
          property="og:image"
          itemprop="image"
          content="http://d90xur3si4z2s.cloudfront.net/ogimage-256x256.png"
        />
        <meta property="og:type" content="website" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#6260a5" />
        <meta name="msapplication-TileColor" content="#6260a5" />
        <meta name="theme-color" content="#ffffff" />
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-ESJ31HCZTL"
        />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-ESJ31HCZTL');
          `}
        </script>
      </Helmet>
      <BurgerBoi open={menuOpen} toggle={toggleMenu} />
      <div className={`noscroll${menuOpen ? " menuOpen" : ""}`}>
        <Layout extraClass="purple">
          <Navigation />
        </Layout>
        <Layout extraClass="purple">
          <MintContainer>
            {/* <MintStep1 /> */}
            <MintStep2 />
          </MintContainer>
        </Layout>
        <Layout extraClass="purple" fullWidth>
          <Footer showTeam={false} />
        </Layout>
        <BurgerMenu goToAnchor={goToAnchor} />
      </div>
    </>
  );
};

export default MintPage;
